@font-face {
  font-family: "Plex Sans";
  src: url("./assets/fonts/PlexSans-Bold.eot");
  src: url("./assets/fonts/PlexSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/PlexSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/PlexSans-Bold.woff") format("woff"),
    url("./assets/fonts/PlexSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/PlexSans-Bold.svg#PlexSans-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plex Sans";
  src: url("./assets/fonts/PlexSans.eot");
  src: url("./assets/fonts/PlexSans.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/PlexSans.woff2") format("woff2"),
    url("./assets/fonts/PlexSans.woff") format("woff"),
    url("./assets/fonts/PlexSans.ttf") format("truetype"),
    url("./assets/fonts/PlexSans.svg#PlexSans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plex Sans";
  src: url("./assets/fonts/PlexSans-SemiBold.eot");
  src: url("./assets/fonts/PlexSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/PlexSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/PlexSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/PlexSans-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/PlexSans-SemiBold.svg#PlexSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plex Sans";
  src: url("./assets/fonts/PlexSans-Medium.eot");
  src: url("./assets/fonts/PlexSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/PlexSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/PlexSans-Medium.woff") format("woff"),
    url("./assets/fonts/PlexSans-Medium.ttf") format("truetype"),
    url("./assets/fonts/PlexSans-Medium.svg#PlexSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
html {
  background: #15181f;
}
html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: #15181f  !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Plex Sans";
  background-color: #15181f !important;
  color: #c4cbd3 !important;
  overflow-x: hidden;
  top: 0 !important;
}
.loader-wrapper{
  background-color: #15181f !important;
}
.checkIcon {
  font-size: 60px;
  margin-bottom: 20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
a {
  color: #fbd334;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #fbd334;
  text-decoration: underline;
}
.addcolor {
  background: #fbd334 !important;
  color: black !important;
}
/* .recharts-wrapper,.recharts-surface,.recharts-responsive-container{
  height: 200px !important;
  width: 500px !important;
} */
a.navbar-brand {
  display: flex;
}
.tooltipflx {
  display: flex;
}
.chart_box {
  height: 340px;
}
.chart_box_error {
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart_box .hide_height {
  overflow: hidden;
}

.chart_box .flx {
  display: flex;
  align-items: flex-end;
}
.chart_box .flx h5 {
  color: #fff;
  margin-bottom: 0;
  margin-right: 5px;
  font-size: 22px;
}
.days ul {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  justify-content: flex-end;
  /* margin-top: 20px; */
}
.days ul li {
  margin: 0 10px;
  font-size: 16px;
  padding: 1px 5px;
  border-radius: 2px;
  cursor: pointer;
  background: #15181f;
}
.days ul li:nth-last-child(1) {
  margin-right: 0;
}
.days ul li:nth-child(1) {
  margin-left: 0;
}
.days ul li.active {
  background: #fbd534;
  color: #111;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #fbd334;
}
a.primary_btn {
  text-decoration: none;
}
.primary_btn {
  background: #fbd334;
  border: 2px solid #fbd334;
  color: #15181f;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  padding: 7px 25px;
  transition: 0.2s all;
}
.primary_btn:hover {
  background: transparent;
  color: #fbd334;
}
.primary_btn a {
  color: #15181f;
  text-decoration: none;
}
.primary_btn:hover a {
  color: #fbd334;
}
/* #offcanvasNavbar .nav-link {
  white-space: nowrap;
}
.right_menu .primary_btn {
  white-space: nowrap;
} */
ul.main_navbar_list {
  margin-left: 0px;
}
ul.main_navbar_list li.nav-item {
  margin: 0 5px;
}
.main_navbar_list li a.nav-link {
  color: #fafdff;
  font-size: 15px;
  font-weight: 600;
}
.main_navbar_list li a.nav-link:focus,
.main_navbar_list li a.nav-link:hover,
.main_navbar_list li a.nav-link.active {
  color: #fbd334;
  text-decoration: none;
}
.navbar-toggler {
  box-shadow: none !important;
}
.main_navbar .offcanvas {
  background: #15181f;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #0f1117 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 1020;
  padding: 12px 0;
}
nav.navbar.main_navbar {
  padding-top: 15px;
  padding-bottom: 15px;
}
.inner_page_wrapper {
  min-height: 90vh;
  padding: 50px 0;
}
.swap_box {
  background-color: #0f1117;
  border-radius: 10px;
  font-size: 14px;
  margin: 0 0 0 auto;
  width: 100%;
  height: fit-content;
  z-index: 1;
  margin-bottom: 30px;
  padding: 20px 25px 22px;
}
.heading_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.heading_block span {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.heading_block i {
  cursor: pointer;
  transition: 0.2s all;
}
.heading_block i img {
  height: 20px;
}
.heading_block i + i {
  margin-left: 20px;
}
.swap_container {
  padding: 15px 0 0;
  position: relative;
  pointer-events: auto;
}
.swap_container label {
  color: #80828a;
  font-size: 16px;
  margin-bottom: 5px;
}
.swap_arrow_container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #0f1117;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.04),
    inset 0 2px 6px rgba(0, 0, 0, 0.04), inset 0 0 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  height: 35px;
  /* transform: translate(-50%,-50%); */
  width: 35px;
  z-index: 2;
  margin: 10px auto -20px;
  position: relative;
}
.swap_select_token_wrap {
  background-color: #000;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  position: relative;
  transition: 0.1s linear;
  /* height: 100px; */
  display: flex;
  align-items: center;
}
.swap_select_token_wrap.swap_select_token_wrap_nobackground {
  border: 1px solid #000;
  background-color: transparent;
}
.swap_select_token_wrap.swap_select_token_wrap_nobackground
  .swap_select_token_option {
  background-color: transparent;
}
.swap_select_token_wrap .swap_select_token:nth-child(2) {
  background-color: #0f1117;
  padding: 7px 9px;
}
.swap_select_token_wrap.swap_select_token_wrap_nobackground
  .swap_select_token:nth-child(2) {
  background-color: #000;
}
.swap_select_token_wrap:nth-of-type(2n) {
  margin-bottom: 3px;
}
.swap_select_token_inner {
  font-weight: 600;
  margin: 10px 15px;
  width: 100%;
}
.swap_select_token_inner > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swap_select_token_inner > div + div {
  margin-top: 5px;
}
.swap_select_token {
  align-items: center;
  cursor: pointer;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  padding: 7px 9px 7px 0;
}
.swap_select_token_option {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  width: 100%;
}
.swap_select_token_option img {
  width: 25px;
  margin-right: 5px;
  border-radius: 50%;
}
.swap_token_title {
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
}
.swap_select_token::after {
  content: url("./assets/images/caret_icon.png");
  margin-left: 10px;
  transform: rotate(0deg);
  transition: 0.2s linear;
}
.swap_token_bal {
  color: #a1a1a1;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}
.swap_token_input {
  border: 0;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: right;
  padding: 0 !important;
  width: 50%;
  background-color: transparent;
}
.swap_token_input:focus-visible {
  outline: none;
}
.token_search_container {
  background-color: inherit;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  -webkit-clip-path: inset(0 -20px -20px -20px);
  clip-path: inset(0 -20px -20px -20px);
  display: flex;
  flex-direction: column;
  height: 0;
  left: 0;
  overflow: hidden;
  padding: 0 15px;
  position: absolute;
  top: 90%;
  transition: 0.2s linear;
  width: calc(100% + 0px);
  z-index: 3;
}
.token_search_container_open {
  height: 330px;
  padding: 10px 15px 40px;
}
.token_search_container h2 {
  font-size: 16px;
  margin-bottom: -10px;
  padding-left: 15px;
  font-weight: 600;
  color: #fff;
}
.token_search_inp {
  background-color: #0f1117;
  color: #fff;
  background-image: url("./assets/images/search_icon.png");
  background-repeat: no-repeat;
  background-position: 10px;
  border-radius: 6px;
  border: 0;
  height: 45px;
  width: 100%;
  padding: 5px 5px 5px 36px;
  outline: none;
}
input::placeholder {
  color: #80828a;
}
.token_search_list {
  display: flex;
  flex-direction: column;
  height: 560px;
  overflow: auto;
  transition: 0.2s linear;
  margin-top: 15px;
  width: 100%;
}
.token_search_list_row {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 98%;
  padding: 12px 10px;
  border-radius: 6px;
  background: rgb(15 17 23 / 100%);
  margin-bottom: 10px;
  position: relative;
}
.token_fav {
  position: absolute;
  right: 15px;
  z-index: 1;
}
.token_search_list_row > div {
  display: flex;
  align-items: center;
}
.token_search_list_row > div:first-child {
  width: 95%;
}
.token_search_list_row:hover {
  background: rgb(15 17 23 / 50%);
}
.token_search_list_row img {
  height: 40px;
  width: 40px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: contain;
}
.roundedimg .token_search_list_row img {
  margin-right: 15px;
  border-radius: 50%;
  width: unset;
}
.token_search_list_cur_name {
  display: block;
  font-weight: 700;
}
.token_search_list_cur_value {
  color: #ababab;
}
.token_search_list_coin_dollar {
  color: #c4cbd3;
}
.swap_info {
  margin-top: 15px;
}
.swap_info > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.swap_info > div:last-child {
  margin-bottom: 0;
}
.swap_info > div span {
  font-size: 14px;
  color: #80828a;
}
.swap_info_accordion {
  margin-bottom: 0;
  margin-top: 10px;
}
.swap_info_accordion .accordion-item,
.swap_info_accordion button {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
.swap_info_accordion button {
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
}
.swap_info_accordion .accordion-button::after {
  margin-left: unset;
  filter: invert(0.8) brightness(0.8);
  background-size: 18px;
}
.swap_info_accordion .accordion-body {
  padding: 0;
  margin-top: 10px;
}
.swap_info_accordion h2.accordion-header {
  position: relative;
}
.swap_info_accordion .swap_info > div:last-child {
  margin-bottom: 0;
}
.swap_info_accordion h2.accordion-header::before {
  content: "";
  background: #000;
  width: 45%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 9px;
}
.swap_info_accordion h2.accordion-header::after {
  content: "";
  background: #000;
  width: 45%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 9px;
}
.accordion_box {
  background: #3c424a;
  border-radius: 3px;
  /* padding: 8px; */
}
.accordion_box .react-tooltip {
  z-index: 5;
}
.accordion_box .box {
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-size: 12px;
  padding: 11px;
  border-bottom: 1px solid #525a63;
}
.accordion_box .box:nth-child(3) {
  border-bottom: 0;
}
.accordion_box .box .flx {
  display: flex;
  align-items: center;
}
.accordion_box .box .grey {
  color: #80828a;
  margin-left: 8px;
}
.cursor_none {
  cursor: text !important;
}
.swap_info_chg > div span > span {
  color: #fff;
}
.swap_info_chg .box_flx {
  display: flex;
  height: 100%;
}
.swap_info_chg .box_flx .box {
  border: 1px solid #55595c;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
}
.swap_info_chg .box_flx .box p {
  margin-bottom: 0;
  color: #fff;
}
.swap_info_chg .box_flx .box p span {
  font-size: 14px;
}
.swap_info_chg .box_flx .box p .asymbol {
  height: 15px;
}
.swap_info_chg .box_flx .box p .dot {
  font-size: 20px;
}
.swap_info_chg .nav-tabs {
  border: 0;
}
.swap_info_chg .nav-item {
  width: 50%;
}
.swap_info_chg .nav-link {
  width: 100%;
  height: 100%;
  border: 0;
}
.swap_info_chg .nav-link.active .box_flx .box {
  border: 1px solid #fbd334;
}
.swap_info_chg .swap_info {
  margin-top: 15px;
}
.swap_info_chg .accordion-button {
  display: inline;

  font-size: 12px;
  color: #fff;
}
/* input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: #e7e6e700;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #d6d6d6;
}

.swap_container_chg label {
  font-size: 13px;
  font-weight: 400;
}
.swap_container_chg .swap_arrow_container {
  margin: -15px auto -15px;
}
.heading_block_chg span a {
  color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  text-decoration: none;
}
.heading_block_chg span a.active {
  color: #fff;
}
.swap_container_limit .limit_flx {
  display: flex;
  margin-top: 10px;
}
.swap_container_limit .limit_flx input {
  text-align: left;
}
.swap_container_limit .limit_flx .buy_box {
  background: #0f1117;
  padding: 2px 4px;
  border-radius: 5px;
  cursor: pointer;
}
.swap_container_limit .limit_flx .dropdown-toggle {
  font-size: 12px;
  padding: 5px !important;
  background: none;
  border: 0;
}
.swap_container_limit .limit_flx .dropdown-menu {
  background: #0f1117;
  border-color: #39414a;
  height: 160px;
  overflow-y: auto;
}
.swap_container_limit .limit_flx .dropdown-menu a {
  color: #fff !important;
  font-size: 14px;
}
.swap_container_limit .limit_flx .dropdown-menu a:hover {
  background-color: #656d79;
  text-decoration: none;
}
.swap_container_limit .limit_flx .dropdown hr {
  margin: 0;
  color: #525252;
}
.swap_container_limit .limit_flx .nowrap {
  white-space: nowrap;
}
.swap_container_limit .limit_flx .left_box {
  width: 100%;
}
.swap_container_limit .limit_flx .left_box .market {
  color: #ffffff;
}
.swap_container_limit .limit_flx .left_box .green {
  color: #0fcb81;
}
.swap_container_limit .limit_flx .right_box {
  width: 25%;
}
.swap_container_limit .limit_flx .right_box .info {
  height: 13px;
}
.swap_container_limit .limit_flx .mar_gin {
  margin: 10px;
}
.swap_container_limit .limit_flx .react-tooltip {
  z-index: 5;
}
.order_table td {
  vertical-align: middle;
}
.order_table tbody tr img {
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
}
.order_table tbody tr .box {
  display: flex;
  align-items: center;
}
.order_table tbody tr .grey {
  display: block;
  color: #7e8796;
  font-size: 12px;
}
.order_table tbody tr p {
  margin-bottom: 0;
}
.order_table tbody tr i {
  color: #5e636b;
  font-size: 17px;
}
.order_table tbody tr .fa-times-circle {
  cursor: pointer;
}
.order_table tbody tr .fa-chevron-right {
  margin-left: 20px;
  color: #aeaeae;
}

.custom_modal .modal-title {
  width: 100%;
  text-align: center;
}
.custom_modal .flxdiv {
  display: flex;
  width: 100%;
}
.custom_modal input,
.custom_modal input:focus {
  background: #5e636b;
  border: 0;
  box-shadow: none !important;
  color: #fff;
  padding: 10px;
}
.custom_modal .input-group-text {
  background: #5e636b;
  border: 0;
  color: #d8d8d8;
}
.custom_modal .flxdiv .primary_btn {
  width: 100%;
  border-radius: 10px;
}
.custom_modal .flxdiv .primary_btn.cancel {
  background: transparent !important;
  color: aliceblue;
}
.custom_modal .flxdiv .primary_btn:hover {
  border: 2px solid #fbd334;
}
.custom_modal_chg {
  padding-left: 30px;
  padding-right: 7px;
  padding-bottom: 10px;
}
.custom_modal_chg .flxdiv {
  justify-content: space-between;
}
.custom_modal_chg .flxdiv.grey span {
  color: #80828a;
}
.custom_modal_chg .flxdiv.grey.flx span {
  display: flex;
  align-items: center;
}
.custom_modal_chg .flxdiv.grey.flx img {
  height: 13px;
}
.custom_modal_chg .flxdiv.grey p {
  display: inline;
  margin-bottom: 0;
  margin-right: 2px;
  color: #fff;
}
.custom_modal_chg input,
.custom_modal_chg input:focus {
  padding: 7px;
}
.connect_wallet_btn {
  font-size: 18px;
  font-weight: 700;
  color: #15181f;
  background: #fbd534;
  border: 2px solid #fbd534;
  border-radius: 5px;
  padding: 10px 20px;
  transition: 0.2s all;
  text-decoration: none !important;
  margin-top: 15px;
  white-space: nowrap;
}
.connect_wallet_btn:hover {
  color: #fbd534;
  background: transparent;
}
footer {
  background: #0f1117;
  padding: 40px 0 20px;
}
.footer h2 {
  font-size: 18px;
  font-weight: 600;
}
.footer_list_links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_list_links li {
  margin: 8px 0;
}
.footer_list_links li a {
  font-size: 14px;
  color: #767981;
  transition: 0.2s all;
}
.footer_list_links li a:hover {
  color: #fbd534;
  text-decoration: none;
}
ul.footer_social_links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}
ul.footer_social_links li + li {
  margin-left: 30px;
}
ul.footer_social_links li a {
  font-size: 20px;
  color: #868686;
  transition: 0.2s all;
}
ul.footer_social_links li a:hover {
  color: #fbd534;
}
.copyright_txt {
  color: #747577;
  font-size: 14px;
  margin-bottom: 0;
  border-top: 1px solid #1d2129;
  padding-top: 15px;
  margin-top: 15px;
}
.btn-close {
  filter: invert(1);
  box-shadow: none !important;
}
.primary_modal .modal-content {
  background-color: #000000;
}
.primary_modal .modal-header {
  border-bottom: none;
}
.primary_modal .modal-title {
  font-size: 20px !important;
  font-weight: 700;
  color: #ffffff;
}
.network_div {
  position: relative;
}
.right_menu .primary_btn {
  font-size: 13px !important;
}
.network_btn {
  background: #0f1117;
  border: 1px solid #39414a;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  font-size: 13px;
  color: #c4cbd3;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  margin-right: 18px;
  position: relative;
  outline: none;
}
.network_btn > div {
  display: flex;
  align-items: center;
}
.network_btn img {
  width: 24px;
  /* margin-right: 10px; */
}
.network_btn span {
  min-width: 75px;
  text-align: left;
}
.network_btn::after {
  content: url("./assets/images/caret_icon.png");
  margin-left: 10px;
  transform: rotate(0deg);
  transition: 0.2s linear;
  display: none;
}
.network_btn_open::after {
  transform: rotate(180deg);
}
.right_menu {
  display: flex;
  align-items: center;
}
.choose_network_container {
  background: #0f1117;
  border: 0px solid #39414a;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  clip-path: inset(0 -20px -20px -20px);
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  overflow: hidden;
  /* padding: 0 15px; */
  position: absolute;
  top: 100%;
  transition: 0.2s linear;
  width: calc(100% + 2px);
  z-index: 3;
  /* opacity: 0; */
  margin-top: 5px;
}
.choose_network_container_open {
  height: 209px;
  overflow-y: scroll;
  /* opacity: 1; */
  /* padding: 10px 15px 20px; */
  border: 1px solid #39414a;
  width: 185px;
}
.choose_network_container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.choose_network_container ul li {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
}
.choose_network_container ul li:hover {
  background-color: #15181f;
}
.choose_network_container ul li img {
  width: 24px;
  margin-right: 12px;
}
.dropdown_menu_custom .dropdown-menu {
  /* display: block !important; */
  background: #0f1117;
  border: 1px solid #0f1117;
  box-shadow: 0 0.5rem 1rem 0.2rem rgba(0, 0, 0, 0.15);
  top: 100% !important;
  /* transform: translateY(20px) !important; */
  transition: all 0.5s;
  /* opacity: 0; */
  width: max-content;
}
/* .dropdown_menu_custom .dropdown-menu.show {
  transform: translateY(0) !important;
  opacity: 1;
  z-index: 9;
} */
.dropdown_menu_custom ul.dropdown-menu li {
  padding: 5px 0;
}
.dropdown_menu_custom ul.dropdown-menu li img {
  margin-right: 8px;
  height: 18px;
}
.dropdown_menu_custom ul.dropdown-menu li a {
  color: #c4cbd3;
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.dropdown_menu_custom ul.dropdown-menu li a:hover {
  background: transparent;
  color: #fbd534;
}
.routing_head_inner img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.routing_row_swap_card img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.nav_pills_primary.nav-pills {
  gap: 15px;
  margin-bottom: 20px;
}
.nav_pills_primary.nav-pills .nav-link {
  border-bottom: 2px solid transparent;
  color: #fff;
  border-radius: 0;
  background: transparent;
  font-size: 16px;
  font-weight: 700;
  padding: 4px 0;
  min-width: auto;
}
.nav_pills_primary.nav-pills .nav-item + .nav-item {
  margin-left: 12px;
}
.nav_pills_primary.nav-pills .nav-link.active {
  background: transparent;
  color: #fff;
  border-bottom: 2px solid #fbd534;
}
.classic_tab h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 30px 0;
}
.routing_container {
  width: 100%;
}
.routing_row {
  width: 100%;
}
.routing_head {
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
}
.routing_head_inner {
  align-items: center;
  display: flex;
}
.routing_head_inner_title {
  font-size: 18px;
  font-weight: 700;
  margin: 0 10px;
  color: #fff;
}
.routing_arrow_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.routing_arrow_left {
  background: url("./assets/images/routing_from.png") no-repeat;
  background-position: 50%;
}
.routing_arrow_div {
  height: 10px;
  width: 25px;
  margin-bottom: 2px;
}
.routing_arrow_right {
  background: url("./assets/images/routing_to.png") no-repeat;
  background-position: 50%;
}
.routing_row_single:first-of-type {
  z-index: 1;
}
.routing_row_single {
  border: 1px dashed rgba(125, 125, 125, 0.37);
  border-radius: 0 0 25px 25px;
  border-top: 0;
  height: 75px;
  position: relative;
  margin: 0 12px;
}
.routing_row_single_inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  position: absolute;
  top: 57px;
  width: 100%;
}
.routing_row_card {
  align-items: center;
  background-color: #464d57;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  position: relative;
  height: fit-content;
  width: fit-content;
  user-select: none;
  z-index: 1;
}
.routing_row_card > span {
  margin: 0 5px;
  font-size: 14px;
}
.routing_row_swap_card {
  color: #fff;
  font-weight: 400;
}
.routing_row_card img:last-of-type {
  display: inline-flex;
  visibility: visible;
  opacity: 1;
}
.routing_row_card div {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;
  width: 150px;
  white-space: normal;
  word-break: break-all;
}
.routing_row_tooltip {
  background-color: #0f1117;
  border-radius: 8px;
  color: #fff;
  padding: 6px 12px;
  position: absolute;
  transform: translate(-50%);
  z-index: 4;
  font-size: 12px;
  bottom: 135%;
  /* width: 100%; */
  left: calc(50%);
}
.custom_tooltip {
  width: 180px !important;
  white-space: normal;
  word-break: normal;
  background-color: #15181f !important;
  color: #fff !important;
  font-size: 12px !important;
  text-align: left !important;
}
.routing_row_card:hover div {
  visibility: visible;
  opacity: 1;
}
.routing_row_tooltip:after {
  content: url("./assets/images/triangle.png");
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translate(-50%);
}
.primary_table thead th {
  color: #7e8796;
  font-size: 14px;
  font-weight: 600;
}
.primary_table thead {
  border-bottom: 1px solid #5e636b;
}
.primary_table thead th,
.primary_table tbody td {
  white-space: nowrap;
  padding: 15px;
  background: #15181f;
}
.primary_table tbody td {
  color: #c4cbd3;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #5e636b;
}
.primary_table tbody td .badge {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
}
.primary_table tbody td .badge.text-bg-success {
  background: #fbd534 !important;
  color: #010000 !important;
  text-transform: uppercase;
  font-weight: 500;
}
.primary_table .align img {
  border-radius: 50%;
}
span.text-bg-danger {
  background-color: rgb(246 70 93 / 10%) !important;
  color: #f6465d !important;
}
.modal_inner_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal_inner_head h2 {
  font-size: 16px;
  font-weight: 400;
  color: #80828a;
}
.modal_grp_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_grp_btns button + button {
  margin-left: 12px;
}
.settings_btn {
  width: 100%;
  font-size: 16px;
  color: #fff;
  border: 1px solid #454e57;
  background: #000;
  border-radius: 10px;
  padding: 12px;
  position: relative;
  overflow: hidden;
}
.settings_btn::after {
  content: "";
  background: url("./assets/images/button_default.png") no-repeat top right;
  width: 52px;
  height: 49px;
  position: absolute;
  top: -12px;
  right: -12px;
}
.settings_btn_selected.settings_btn {
  border: 1px solid #ffd64d;
}
.settings_btn_selected.settings_btn::after {
  background: url("./assets/images/button_selected.png") no-repeat top right;
}
.slipage_inp_grp {
  margin-top: 30px;
}
.slipage_inp_grp input {
  background: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: none !important;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff !important;
}
.slipage_inp_grp .input-group-text {
  background-color: #000000;
  border: 1px solid #000000;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 10px;
}
.slipage_inp_grp_nobg.slipage_inp_grp input {
  background: transparent !important;
  border: 1px solid #464d57 !important;
  border-right: 0 !important;
}
.slipage_inp_grp_nobg.slipage_inp_grp .input-group-text {
  background-color: transparent;
  border: 1px solid #464d57;
  border-left: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.custom_hr {
  border-color: #5e636b;
  opacity: 1;
  margin: 30px 0;
}
.react-tooltip {
  width: 300px !important;
  font-size: 12px;
  white-space: normal;
}
.modal-backdrop.show {
  background: rgb(13 15 20 / 90%);
  opacity: 0.9;
}
.connect_wallet_row {
  border: 2px solid #5e636b;
  background: #0f1117;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.connect_wallet_row h2 {
  font-size: 15px;
  font-weight: 400;
  color: #c4cbd3;
  position: absolute;
  top: -9px;
  margin: 0;
}
.connect_wallet_row h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 15px;
}
.connect_wallet_row h3 a {
  color: #fff;
}
.connect_wallet_row .form-check-input[type="checkbox"] {
  background-color: #222930;
  border: 1px solid #686c77;
  box-shadow: none !important;
}
.connect_wallet_row .form-check-input:checked[type="checkbox"] {
  background-color: #026bea;
  border: 1px solid #026bea;
}
.connect_wallet_row::after {
  content: "";
  background-color: #0f1117;
  width: 60px;
  height: 15px;
  position: absolute;
  top: -2px;
  left: 20px;
  z-index: -1;
}
.network_row {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  margin-top: 20px;
}
.network_row img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  filter: grayscale(1) !important;
}
.network_last_row img {
  filter: unset !important;
}
.network_row > div.network_selected img {
  filter: unset !important;
}
.network_row > div {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.network_row p {
  margin: 5px 0 0;
  color: #c4cbd3;
  font-size: 15px;
}
.network_selected::after {
  content: "";
  background: url("./assets/images/select_icon.png") no-repeat bottom right;
  width: 21px;
  height: 21px;
  position: absolute;
  bottom: 25px;
  right: 15px;
  filter: grayscale(1);
}
.connect_wallet_row_active .network_row img,
.connect_wallet_row_active .network_selected::after {
  filter: unset;
}
.form-check-input:checked {
  box-shadow: none !important;
}
.form-check-input:checked[type="checkbox"] {
  filter: hue-rotate(20deg) invert(1);
}
/* .dropdown-toggle::after {
  content: url("./assets/images/menu_arrow.png");
  filter: brightness(0) invert(1);
  border: none;
  vertical-align: top;
  margin-left: 10px;
}
.dropdown-toggle.show::after {
  filter: unset;
} */
.bridge_flex {
  display: flex;
  justify-content: center;
}
.bridge_flex .swap_box {
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.bridge_left {
  width: 36%;
  margin-right: 10px;
}
.bridge_left .swap_box {
  background: #0f1117 url("./assets/images/bridge_bg.png") no-repeat bottom
    right;
}
.bridge_left p {
  color: #828a95;
  font-size: 16px;
  margin: 30px 0;
}
.bridge_left a {
  display: block;
  font-size: 16px;
  margin-bottom: 12px;
}
.bridge_right {
  width: 56%;
}
.bridge_inp label {
  margin-bottom: 0;
  color: #80828a;
  font-size: 14px;
}
.bridge_inp button span {
  background-color: #fbd534;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1px 12px;
  border-radius: 20px;
}
.bridge_info_accordian {
  margin-bottom: 30px;
}
.bridge_info_accordian .accordion-item,
.bridge_info_accordian button {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
.bridge_info_accordian .accordion-body {
  padding: 0;
}
.bridge_info_accordian h2 button {
  position: relative;
  color: #80828a !important;
  font-size: 16px;
  justify-content: space-between;
  padding: 0 0 15px;
}
.bridge_info_accordian .swap_info > div:last-child {
  margin-bottom: 0;
}
.bridge_info_accordian .accordion-button::after {
  filter: invert(0.5);
}
.bridge_info_accordian h2 button span:nth-child(2) {
  text-align: right;
  margin-right: 8px;
}
.bridge_info_accordian h2 button span {
  width: 100%;
}
.bridge_wallet_confirm p {
  font-size: 16px;
  color: #fff;
}
.confirm_wallet_info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.confirm_wallet_info > div {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
.confirm_wallet_info div img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.confirm_wallet_inp {
  background: #464d57;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 10px;
  margin-top: 8px;
  color: #fff;
}
.confirm_wallet_inp label:first-child {
  color: #828b99;
  font-weight: 500;
}
.bridge_wallet_confirm .confirm_wallet_inp {
  background-color: #000000;
}
.bridge_wallet_confirm .confirm_wallet_inp_nobg {
  border: 1px solid #464d57;
  background-color: transparent;
}
.bridge_confirmation_row {
  margin-top: 50px;
  margin-bottom: 80px;
}
.bridge_confirmation_row > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.bridge_confirmation_row div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bridge_crypto {
  width: 46px;
  height: 46px;
  margin-bottom: 10px;
}
.bridge_confirmation_row > div::after {
  content: "";
  background: #5e636b;
  width: 70%;
  height: 1px;
  position: absolute;
  top: 23px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.bridge_confirmation_time {
  color: #80828a;
  font-size: 16px;
  text-align: center;
  margin: 0 0 -10px;
}
.bridge_confirmation_fees {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: -40px 0 0;
}
.bridge_confirmation p.bridge_note {
  color: #828a95;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
.bridge_confirmation p.bridge_note span {
  color: #0fcb81;
}

.flx_line {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.flx_line.cursor {
  cursor: pointer;
}
.flx_line .form-check-input:focus {
  border-color: #86b7fe00;
  box-shadow: none;
}
.flx_line .flx_line_div {
  display: flex;
  align-items: center;
}
.flx_line .flx_line_div p {
  margin-bottom: 0;
}
.flx_line .flx_line_div .info_img {
  margin-left: 10px;
  height: 15px;
  color: #68717e;
  font-size: 14px;
}
.flx_line .form-check-input:not(:checked) {
  background-color: #9b9eac;
}
.flx_line .flx_line_div .symbol {
  margin-right: 10px;
  color: #68717e;
  font-size: 14px;
}
.flx_line .flx_line_div span {
  color: #68717e;
}
h5#offcanvasNavbarLabel {
  visibility: hidden;
}
.wallet_details h3 {
  font-size: 16px;
  margin-bottom: 0;
  color: #adadad;
  cursor: default;
}
.wallet_details h3 span {
  color: #c5c5c5;
  word-break: break-word;
  user-select: none;
}
.copied_txt {
  background-color: #00a92f;
  color: #fff;
  padding: 15px;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 999;
  border-radius: 5px;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 15px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: 15px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
.wallet_details img {
  width: 16px;
  margin-left: 15px;
  cursor: pointer;
}
#confirm_swap_modal .swap_select_token::after {
  display: none;
}
#confirm_swap_modal .swap_token_input {
  pointer-events: none;
  user-select: none;
}
.loading {
  margin: 0 auto;
}
.no_token_search_list_row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no_token_search_list_row img {
  width: 120px;
}
.no_token_search_list_row h2 {
  font-size: 24px;
  margin: 20px 0 8px;
}
.no_token_search_list_row h3 {
  font-size: 16px;
  color: #979797;
}
.disabledbinance {
  filter: grayscale(1);
}
.errmsg {
  font-size: 14px;
  color: #ff3a3a;
  margin-bottom: 0;
}
.gasfee_selector_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  background: #000000;
  padding: 8px;
  border-radius: 5px;
}
.gasfee_selector_wrap button {
  font-weight: 400;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.2s all;
  color: #fff;
}
.gasfee_selector_wrap button.selected {
  background: #15181f !important;
}
.gasfee_selector_wrap input[type="number"] {
  width: 120px;
  border: 1px solid #15181f;
  border-radius: 5px;
  padding: 4px 10px;
  background: #000000;
  color: #fff;
  outline: none;
}

/* Blog page css starts */
.blog_header {
  padding-top: 100px;
  padding-bottom: 80px;
}
.blog_search {
  max-width: 75%;
  margin: 30px auto;
}
.blog_search input {
  background: #0f1117 !important;
  border: 1px solid #0f1117 !important;
  border-radius: 10px;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
}
.blog_search button.input-group-text {
  background-color: #070708;
  border-radius: 10px;
  border-color: #070708;
  color: #fff;
  font-size: 24px;
  padding: 2px 20px;
}
.blog_search input::placeholder {
  color: #b3b3b3;
}
.blog_search_keywords {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog_search_keywords p {
  margin-bottom: 0;
  font-size: 14px;
  color: #a5a5a5;
}
.blog_search_keywords ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.blog_search_keywords ul li {
  position: relative;
  margin: 0 10px;
}
.blog_search_keywords ul li:after {
  content: "";
  background-color: #a5a5a5;
  height: 13px;
  width: 1px;
  position: absolute;
  right: -10px;
  top: 6px;
}
.blog_search_keywords ul li:last-child::after {
  display: none;
}
.blog_search_keywords a {
  font-size: 14px;
  color: #eee;
}
.blog_search_keywords a:hover {
  color: #fbd334;
  text-decoration: none;
}
.blog_type_box {
  background-color: #0f1117;
  border: 1px solid #0f1117;
  padding: 30px;
  border-radius: 20px;
  cursor: default;
  transition: 0.2s all;
  margin-bottom: 30px;
  min-height: 365px;
}
.blog_type_box:hover {
  border: 1px solid #464d57;
}
.blog_type {
  padding: 4px 15px;
  border-radius: 30px;
  display: table;
  margin-left: auto;
  margin-bottom: 15px;
}
.blog_type span {
  color: #cbcbcb;
  font-size: 14px;
}
.blog_type i {
  font-size: 8px;
  margin-right: 7px;
  top: -3px;
  position: relative;
}
.blog_type_begin {
  color: #00b131;
  background: rgb(0 177 49 / 15%);
}
.blog_type_advance {
  color: #f1e904;
  background: rgb(241 233 4 / 15%);
}
.blog_type_tech {
  color: #ff1414;
  background: rgb(255 20 20 / 15%);
}
.blog_type_box h4 {
  font-size: 24px;
  color: #fff;
  margin: 20px 0 30px;
  font-weight: 600;
  line-height: 32px;
}
section {
  padding: 60px 0;
}
.blog_topic_list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 15px;
  margin-top: 30px;
}
.blog_topic_list li a {
  background: #0f1117;
  border-radius: 30px;
  padding: 10px 25px;
  color: #c5c5c5;
  text-decoration: none;
  transition: 0.2s all;
}
.blog_topic_list li a:hover {
  background: #0f1117;
}
.blog_wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  margin-top: 50px;
  gap: 1.5rem;
}
.blog_wrap a {
  text-decoration: none !important;
}
.blog_single {
  position: relative;
  background: #0f1117;
  border-radius: 10px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  overflow: hidden;
}
.blog_content {
  padding: 20px;
  min-height: 260px;
  margin-bottom: 30px;
}
.blog_single:hover {
  transform: scale(1.05);
}
.blog_img {
  height: 230px;
}
.blog_img img.img-fluid {
  height: 230px;
  width: 100%;
  object-fit: cover;
}
.blog_content .blog_type i {
  font-size: 6px;
  margin-right: 7px;
  top: -2px;
}
.blog_content .blog_type {
  padding: 2px 12px;
  display: inline-block;
  margin: 0 0 15px;
}
.blog_content .blog_type span {
  font-size: 12px;
}
.blog_content .blog_topic_list {
  margin-top: 0;
}
.blog_content .blog_topic_list li a {
  padding: 5px 12px;
  font-size: 14px;
  background: #15181f;
}
.blog_content h2 {
  font-size: 18px;
  line-height: 28px;
  color: #eee;
  margin: 15px 0 10px;
}
.blog_content p {
  color: #bdbdbd;
  font-size: 14px;
  margin-bottom: 0;
}
.blog_date {
  font-size: 13px !important;
  text-align: right;
  color: #8f8f8f !important;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.blog_header h1 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 15px;
}
.blog_header h5 {
  line-height: 32px;
  font-size: 20px;
  color: #ebebeb;
}
.blog_detail_header img {
  height: 600px;
  width: 600px;
  object-fit: cover;
  display: block;
  margin: 30px auto;
}
.blog_detail_typeflex .blog_type {
  display: block;
  margin: unset;
}
.blog_detail_typeflex .blog_topic_list {
  margin-top: 0;
}
.blog_detail_typeflex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 15px;
}
.blog_detail_typeflex .blog_topic_list li a {
  font-size: 14px;
  user-select: none;
  pointer-events: none;
}
.blog_header.blog_detail_header {
  padding-bottom: 0;
}
.blog_detail_content h2 {
  color: #eee;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 15px;
}
.blog_detail_content p {
  color: #cdcfd1;
  margin-bottom: 30px;
  line-height: 28px;
}
.blog_detail_content li {
  margin-bottom: 20px;
  line-height: 28px;
}
.blog_detail_content h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #eee;
}
hr.blog_hr {
  margin: 40px 0;
  border-color: #787878;
}
.blog_detail_content h5 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #eee;
}
.blog_detail_card {
  background: #0f1117;
  padding: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-top: 0 !important;
}
.blog_detail_card_title {
  font-size: 20px;
  color: #fff;
  padding: 15px;
  background-color: #1d242c;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  text-transform: uppercase;
  margin-top: 0;
}
.blog_detail_card_list {
  margin: 0;
  padding: 10px 15px 5px;
  list-style: none;
}
.blog_detail_card_list li {
  padding-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.blog_detail_card_list li a {
  color: #d1d1d1;
  text-decoration: none;
  font-size: 15px;
}
.blog_detail_card_list li:before {
  content: "\F280";
  font-family: bootstrap-icons !important;
  font-display: swap;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 2px;
  color: #d1d1d1;
  font-size: 13px;
}
.blog_search_container .blog_search {
  max-width: 100%;
  margin: 0;
}
.blog_search_container .blog_wrap {
  margin-top: 0;
}
.blog_difficulty_select {
  max-width: 35%;
  margin: 15px auto 0;
}
.blog_difficulty_select select {
  color: #fff;
  border: 1px solid #464d57 !important;
  background: #464d57 url("./assets/images/caret_icon.png") no-repeat right
    0.75rem center;
  box-shadow: none !important;
  padding: 12px;
}
.search_results_top .blog_difficulty_select {
  margin: unset;
  max-width: unset;
  width: 200px;
}
.search_results_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.blog_search_results_left {
  position: sticky;
  top: 100px;
}
.blog_search_results_left ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.blog_search_results_left ul li {
  margin: 10px 0;
}
.blog_search_results_left ul li a {
  text-decoration: none;
  color: #bdbdbd;
  background: transparent;
  transition: 0.2s all;
  padding: 10px 20px;
  display: block;
  border-radius: 5px;
}
.blog_search_results_left ul li a:hover,
.blog_search_results_left ul li a.active {
  background: #fbd334;
  color: #000;
}
ul.pagination.custom_pagination {
  justify-content: flex-end;
}
ul.pagination.custom_pagination .page-link {
  background-color: #0f1117;
  border: 1px solid #0f1117;
  color: #fff;
  text-decoration: none;
}
ul.pagination.custom_pagination .active > .page-link,
ul.pagination.custom_pagination .page-link.active {
  background-color: #fbd334;
  color: #000;
}
.blog_search_header {
  background: #0f1117 url("./assets/images/search_bg.png") no-repeat center;
}
/* Blog page css ends */

/* Home page css starts */
.page_header {
  padding: 100px 0;
}
.page_header h1 {
  font-size: 58px;
  font-weight: 600;
  color: #fff;
}
.txt_yellow {
  color: #fbd534 !important;
}
.page_header h5 {
  font-size: 16px;
  font-weight: 400;
  color: #939aa4;
  margin: 15px 0 30px;
  line-height: 32px;
}
span.react-rotating-text-cursor {
  visibility: hidden;
}
.banner_token_info {
  border: 2px solid #0f1117;
  background: #0f1117;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 100px;
  margin-top: 100px;
}
.banner_token_info > div {
  text-align: center;
}
.banner_token_info > div h2 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}
.banner_token_info > div h2 span {
  color: #fbd534;
}
.banner_token_info > div p {
  margin-bottom: 0;
  color: #939aa4;
  font-size: 18px;
}
.main_title {
  font-size: 46px;
  font-weight: 600;
  color: #fff;
}
.inner_para {
  color: #939aa4;
  font-size: 18px;
}
/* .landing_networks_div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
} */
.landing_networks_div {
  margin-top: 30px;
}
.landing_networks_div > div {
  text-align: center;
}
.landing_networks_div > div p {
  font-size: 18px;
  color: #939aa4;
  margin-top: -70px;
}
.landing_networks_div [class*="col-"] {
  margin-bottom: 30px;
}
.sub_title {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
.rotating_circle_keys {
  position: relative;
  overflow: hidden;
}
.rotating_circle_keys .rotating_circle  {
  position: relative;
}
.rotating_circle_keys .rotating_circle::before {
  content: "";
  background: url("./assets/images/img_01.png") no-repeat center;
  width: 812px;
  height: 852px;
  position: absolute;
  bottom: -190px;
  z-index: -1;
  left: -110px;
}
section.home_section {
  padding: 80px 0;
}
.rotating_circle {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.center_img {
  position: absolute;
  left: calc((500px - 200px) / 2);
  top: calc((500px - 200px) / 2);
  width: 200px;
  height: 200px;
  border-radius: 100px;
  /* background: url("./assets/images/rotate_icon_center.png") center center
    no-repeat; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  z-index: 10;
}
.middle,
.outer {
  position: absolute;
  pointer-events: none;
  z-index: -2;
}
.middle {
  left: calc((500px - 291px) / 2);
  top: calc((500px - 291px) / 2);
  width: 291px;
  height: 291px;
  animation: rotate 35s linear infinite;
}
.outer {
  left: calc((500px - 415px) / 2);
  top: calc((500px - 415px) / 2);
  width: 415px;
  height: 415px;
  animation: rotate 45s reverse linear infinite;
}
.middle.dots,
.outer.dots {
  border: 3px dashed #39414a;
  border-radius: 500px;
}
.dot {
  position: absolute;
  background-repeat: no-repeat;
}
.icon-buch {
  background-image: url("./assets/images/rotate_icon_03.png");
  width: 95px;
  height: 95px;
  left: -38px;
  top: calc(50% - 10px);
}
.icon-screen {
  background-image: url("./assets/images/rotate_icon_02.png");
  width: 97px;
  height: 97px;
  left: -127px;
  top: calc(50% - 127px);
}
.icon-kompass {
  background-image: url("./assets/images/rotate_icon_01.png");
  width: 103px;
  height: 103px;
  left: -40px;
  top: calc(50% - 127px);
}
.arbitrum{
  background-image: url("./assets/images/arbitrum.png");
}
.avalanche{
  background-image: url("./assets/images/avalanche1.png");
  width: 80px;
  height: 80px;
  background-size: contain;
}
.base{
  background-image: url("./assets/images/base.png");
}
.fantom{
  background-image: url("./assets/images/fantom1.png");
  width: 90px;
  height: 90px;
  background-size: contain;
}
.optimism{
  background-image: url("./assets/images/optimism.png"); 
  width: 95px;
  height: 95px;
  background-size: contain;
}
.eth{
  background-image: url("./assets/images/eth.png"); 
  width: 85px;
  height: 85px;
  background-size: contain;
}
/* .icon-film {
  background-image: url("../images/rotate_icon_04.png");
  width: 337px;
  height: 159px;
  left: -56px;
  top: calc(50% - 154px)
} */
.dot:nth-child(2) {
  left: calc(100% - 67px);
}
.target:nth-child(2) {
  left: calc(100% - 32px);
}
.middle .dot {
  animation: rotate 35s reverse linear infinite;
}
.outer .dot {
  animation: rotate 45s linear infinite;
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.assets_section {
  position: relative;
  overflow: hidden;
}
.assets_section .center_img {
  left: calc((500px - 365px) / 2);
  top: calc((500px - 365px) / 2);
  width: 365px;
  height: 365px;
  /* background: url("./assets/images/rotate_icon_04.png") center center no-repeat; */
}
.assets_section .rotating_circle,
.wealth_section .rotating_circle {
  position: relative;
}
.assets_section .rotating_circle::before {
  content: "";
  background: url("./assets/images/dot_01.png") no-repeat right;
  width: 812px;
  height: 812px;
  position: absolute;
  top: -140px;
  right: -150px;
}
.wealth_section {
  background-color: #0e1015;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.wealth_section .center_img {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  height: 180px;
}
.wealth_section .rotating_circle::before {
  content: "";
  background: url("./assets/images/img_022.png") no-repeat center left;
  width: 812px;
  height: 812px;
  position: absolute;
  bottom: -150px;
  left: -140px;
}
.frame_box div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.frame_box h3 {
  font-size: 18px;
  color: #939aa4;
  margin-bottom: 0;
  margin-left: 10px;
  font-weight: 700;
}
.frame_box h2 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
  max-width: 70%;
}
.frame_box {
  background: #0f1117;
  border-radius: 10px;
  padding: 30px;
  min-height: 280px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.frame_box a {
  text-decoration: underline;
  font-size: 16px;
}
.home_section_div {
  border: 1px solid #39414a;
  border-radius: 15px;
  padding: 50px 70px;
}
.home_section_div_flex {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.home_section_div_left {
  min-width: 50%;
}
.home_section_div_left ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 30px;
}
.home_section_div_left ul li {
  margin-bottom: 15px;
}
.home_section_div_left ul li a img {
  width: 22px;
}
.home_section_div_left ul li a i {
  margin-right: 10px;
}
.home_section_div_left ul li a {
  color: #dfdfdf;
  transition: 0.2s all;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.home_section_div_left ul li a:hover {
  color: #fbd334;
}
.home_section_div_right .frame_box a {
  text-decoration: none;
}
.home_section_div_flex h2.sub_title {
  font-size: 28px;
}
.home_section_div_left p.inner_para {
  max-width: 50%;
}
.banner_network_search {
  background: #0f1117;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 30px 0;
}
.banner_network_search .form-control {
  background: #0f1117;
  border: 1px solid #0f1117 !important;
  box-shadow: none !important;
  color: #fff;
}
.banner_network_search .form-control::placeholder {
  color: #ffffff;
}
.banner_network_search button {
  background: #fbd534;
  border: 1px solid #fbd534;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 4px 20px 4px 12px;
  border-radius: 6px;
}
.banner_network_search button img {
  margin-left: 15px;
  filter: invert(1);
}
.banner_network_search_wrap {
  position: relative;
  background: #0f1117;
  border-radius: 10px;
  width: 75%;
}
.page_header img {
  user-select: none;
  pointer-events: none;
}
.d_d_none {
  display: none !important;
}
/* Home page css ends */

.chart_box .loading {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apexcharts-tooltip {
  background: #f3f3f3;
  color: #111;
  padding: 10px;
}
.chart_failed_msg {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goog-te-gadget span {
  display: none;
}
.goog-te-gadget {
  font-size: 0 !important;
}
.goog-te-gadget .goog-te-combo {
  background: #0f1117;
  border: 1px solid #39414a;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  font-size: 15px;
  color: #c4cbd3;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 0 0 0 15px !important;
  position: relative;
  outline: none;
  width: 160px;
}
.skiptranslate iframe {
  display: none;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

/* Preloader */
/* #loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1101;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 1103;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fbd534;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #15181f;
  z-index: 1000;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0); 
  transform: translateX(0); 
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
} */
/* Loaded */
/* .loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
} */
/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #222222;
}
/* body.loading {
  overflow: hidden;
} */
.makeStyles-listItem-90:after {
  display: none !important;
}
.popularlist {
  cursor: pointer;
}
.popularlist img {
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.whole_popularlist {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.whole_popularlist .popularlist {
  display: flex;
  align-items: center;
  background: #0f1117;
  border-radius: 5px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  position: relative;
}
.whole_popularlist .popularlist span {
  font-weight: 400;
}
.popularlist_close {
  position: absolute;
  top: -4px;
  right: -5px;
  opacity: 0;
  transition: 0.2s all;
}
.popularlist:hover .popularlist_close {
  opacity: 1;
}
.h_flx {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #f3f3f3;
  justify-content: space-between;
}
.h_flx span {
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.sticky {
  position: sticky;
  width: fit-content;
  top: 90px;
}
.adjust_height {
  height: 100%;
  max-height: 400px;
}
.swap_limit_inp_flex {
  display: flex;
  gap: 15px;
  margin: 5px 0 12px;
}
.swap_limit_inp_flex > div {
  width: 100%;
}
.swap_limit_inp_flex .swap_token_input {
  width: 100%;
  text-align: left;
  padding: 10px 12px !important;
  border-radius: 8px;
  font-size: 16px;
}
.inp_grp {
  position: relative;
}
.swap_limit_inp_token {
  position: absolute;
  top: 12px;
  right: 12px;
}
.limitdisplay div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.limitdisplay div span {
  font-size: 13px;
}
.limitdisplay div span:nth-child(2) {
  color: #fff;
}
.limitdisplay {
  margin-top: 20px;
}
.isPairList {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #5e636b;
  margin-top: 20px;
  padding-bottom: 5px;
}
.isPairList p {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
}
.isPairList p.active {
  color: #ffc107;
}
.avltoken img {
  width: 36px;
  border-radius: 50%;
}
.avltoken {
  display: flex;
  align-items: center;
  gap: 15px;
}
.avltoken h5 {
  margin-bottom: 3px;
  font-size: 15px;
  color: #fff;
}
.avltoken h6 {
  margin-bottom: 0;
  font-size: 12px;
}
.buy_sell_btn_grp {
  display: flex;
  background: #464d57;
  border-radius: 8px;
  width: 100%;
  position: relative;
  margin: 15px 0 0;
}
.buy_sell_btn_grp button {
  height: 48px;
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
  z-index: 2;
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
  justify-content: center;
  transition: transform 0.2s;
  border-radius: 8px;
  font-size: 18px;
}
.buy_sell_btn_grp button.buy_btn.active {
  background: #67ba58;
  border: 1px solid #67ba58;
}
.buy_sell_btn_grp button.sell_btn.active {
  background: #f84960;
  border: 1px solid #f84960;
}

/**** tick symbol withdraw******/
.txcomplete {
  width: 80px;
  display: block;
  margin: 23px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

p {
  &.success {
    color: #73af55;
  }
  &.error {
    color: #d06079;
  }
}
.footer_blw p {
  margin-bottom: 0;
  font-size: 16px;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
/**** tick symbol withdraw******/
.wallet_address_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  border-radius: 30px;
  background: #1b2027;
  margin: 10px 0 15px;
}
.wallet_balance_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wallet_balance_div label:nth-child(2) {
  color: #fff;
}
.routing_container_wrap {
  border: 1px solid #25272e;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.routing_container_wrap h2 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}
.active_order_filter {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.search_grp {
  position: relative;
  width: 100%;
}
.search_grp input {
  background: #0f1117 !important;
  border: 1px solid #0f1117 !important;
  padding: 12px 15px 12px 45px;
  color: #fff !important;
  box-shadow: none !important;
}
.search_grp input::placeholder {
  color: #484747;
}
.search_grp img {
  position: absolute;
  top: 18px;
  left: 15px;
}
button.cancel_btn {
  font-size: 18px;
  font-weight: 700;
  color: #fbd534;
  background: #0f1117;
  border: 2px solid #0f1117;
  border-radius: 5px;
  padding: 10px 20px;
  transition: 0.2s all;
  text-decoration: none !important;
  white-space: nowrap;
}
button.cancel_btn:hover {
  background: transparent;
  border: 2px solid #fbd534;
}
.noorder_div {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.swap_select_token_wrap.swap_select_token_wrap_nobackground
  .swap_select_token.swap_select_token_nobg {
  background-color: transparent !important;
}
.modal-body .swap_select_token_wrap {
  background-color: #0f1117;
  border: 1px solid #0f1117;
}
.modal-body .swap_select_token_wrap.swap_select_token_wrap_nobackground {
  border: 1px solid #0f1117;
  background-color: transparent;
}
.modal-body .swap_arrow_container {
  background-color: #000;
}
.otc_last {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 15px;
}
.otc_last label {
  color: #a6a6a6;
  font-size: 13px;
}
.otc_last label span {
  color: #fbd534;
}
.otc_last_left {
  width: 54%;
}
.otc_last_right {
  width: 46%;
}
.flex_btn_grp {
  display: flex;
  align-items: center;
  gap: 15px;
}
.rate_lock {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 5px;
}
.rate_lock img {
  cursor: pointer;
}
.lock_icon {
  filter: brightness(0) invert(1);
}
.lock_icon.active {
  filter: unset;
}
.otc_last .input-group.slipage_inp_grp {
  margin: 5px 0;
}
.otc_last .slipage_inp_grp input {
  padding: 8px;
}
.otc_last_right .slipage_inp_grp .input-group-text select {
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 16px;
  outline: none;
  font-weight: 700;
  padding: 4px 0;
}
.taker_address_inpgrp {
  position: relative;
}
.taker_address_inpgrp input {
  background-color: #21252c;
  border: 1px solid #21252c;
  border-radius: 10px;
  color: #fefefe;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 85px 10px 15px !important;
  min-height: 50px;
  width: 100%;
}
.taker_address_inpgrp input::placeholder {
  color: rgba(254, 254, 254, 0.2);
}
.taker_address_inpgrp button {
  position: absolute;
  background: #fbd534;
  border: 1px solid #fbd534;
  padding: 3px 6px;
  color: #010000;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  top: 12px;
  right: 10px;
}
.otc_disclaimer {
  border: 1px solid #464d57;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}
.otc_disclaimer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.otc_disclaimer > div h5 {
  color: #fbd534;
  font-size: 14px;
  margin-bottom: 0;
}
.otc_disclaimer .yellow_txt {
  color: #fbd534;
}
.otc_disclaimer p {
  font-size: 13px;
  margin-bottom: 0;
}
.refund_txt {
  background: #fbd534;
  border-radius: 12px;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000 !important;
  padding: 4px;
  margin-right: 10px;
  cursor: default;
  user-select: none;
}
span.order_cancel {
  color: #ff453a !important;
  background: rgba(255, 59, 48, 0.15);
}
span.order_filled {
  color: #26dc7b !important;
  background: rgba(38, 220, 123, 0.15);
}
span.order_expired {
  color: #7c8497 !important;
  background: rgba(32, 46, 73, 0.7);
}
span.order_open {
  color: #5ac8fa !important;
  background: rgba(90, 200, 250, 0.1);
}
span.order_cancel,
span.order_expired,
span.order_filled,
span.order_open {
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px !important;
  box-shadow: rgb(9, 22, 46) 0px 6px 12px;
}
.filed_order_btn {
  color: #222;
  font-size: 13px;
  background: rgb(251 214 74);
  text-transform: uppercase;
  border: 1px solid #fbd64a;
  border-radius: 5px;
}
.swap_select_token_noarrow.swap_select_token::after {
  display: none;
}
button.filed_order_btn.cancel_btn {
  background: #f00;
  border: 1px solid #f00;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 1px 5px;
  border-radius: 5px;
  margin-left: 10px;
}
input.disabled_input {
  cursor: not-allowed;
}
.maxtoken_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.maxtoken_wrap label {
  color: #fbd534;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.token_search_list_row.eligibletokens_disabled {
  filter: grayscale(1);
}
.home_banner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
}
.swap_home {
  width: 420px;
}
.banner_network_search_wrap .token_search_list_row {
  background: rgb(0 0 0 / 40%);
}
.banner_network_search_wrap .token_search_list_row:hover {
  background: rgb(0 0 0 / 80%);
}
.address_tooltip {
  white-space: normal;
  word-break: break-all;
}
.order_rate {
  cursor: pointer;
}
ul.search_network_list {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 10px 15px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 180px;
}
ul.search_network_list li {
  background: #0f1117;
  padding: 10px;
  min-width: 100px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s all;
  height: 40px;
}
ul.search_network_list li:hover {
  background: rgb(15 17 23 / 75%);
}
ul.search_network_list li.active {
  background: #fbd534;
  color: #111;
}
.language-switch {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
}
.language-button {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  gap: 5px;
}
.language-button svg {
  margin-right: 8px;
}
.language-button:hover {
  transition: 0.2s;
  color: #fff;
}
.language-change-popup {
  background: #0f1117;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 24px;
  position: absolute;
  width: 516px;
  top: 52px;
  right: -20px;
  display: none;
}
.popup-show {
  display: block;
  z-index: 1;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #000;
}
.popup-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0;
  color: #fff;
}
.popup-close {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.popup-close::before {
  font-size: 30px;
}
.popup-close:hover {
  color: #fff;
  transition: 0.2s;
}
.popup-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px 24px;
  height: 350px;
  overflow: auto;
}
.popup-lang-item {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
  padding: 12px 16px;
  cursor: pointer;
  text-decoration: none !important;
}
.popup-lang-item img {
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
}
.popup-lang-item:hover {
  padding: 11px 15px;
  color: #fff;
  background: #000;
  border: 1px solid rgba(50, 64, 84, 0.3);
  border-radius: 8px;
}
.popup-lang-item.mobile-menu-sub-link {
  margin-bottom: 8px !important;
  justify-content: start !important;
}
.popup-lang-abbr {
  text-transform: uppercase;
}
.mobile-lang-wrap {
  display: flex;
  align-items: center;
}
.mobile-lang-abbr {
  margin-right: 6px;
  text-transform: uppercase;
}
img.signlogo {
  margin: 30px 0;
}
.token_search_list_row .fa-thumbtack {
  margin-left: 8px;
  transform: rotate(45deg);
}
.token_search_list_row .fa-thumbtack.ispopular {
  color: #fbd334;
}
.token_search_list_row .primary_btn {
  padding: 5px 20px;
}
.import_token_details {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #0f1117;
}
.import_token_details_left img {
  width: 40px;
  border-radius: 50%;
}
.import_token_details_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.import_token_details_left h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-bottom: 0;
}
.import_token_details_left h5 {
  font-size: 13px;
  line-height: 16px;
  padding: 2px 4px;
  background: #0f1117;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  margin-bottom: 0;
}
.import_token_details_left div > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.import_token_details_left div > div p,
.import_token_details_right p {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
}
.import_token_details_right {
  color: #eee;
}
.import_token_details_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.fa-copy {
  cursor: pointer;
}
.yellow_box {
  background: #101010;
  background-size: cover;
  border-radius: 8px; 
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  padding: 15px 15px;  
}
.yellow_box p {
  color: #fff;
  font-weight: 500;
}
#ImportTokenModal .alert {
  border-radius: 8px; 
  color: #111;
}
.main_wrapper {
  min-height: 60vh;
}

.banbox{
  border: 1px solid #464d57;
  border-radius: 10px;
  padding: 15px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 20px;
  display: inline-block;
}
.banbox p{
  font-size: 16px;
}

.link-yellow{
  color: #fbd334 !important;
}
img.ban_img {
  width: 350px;
}
.page_header h2,
.page_header h3,
.page_header h4,
.page_header h5,
.page_header h6,
.page_header p,
.page_header span {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  /* .goog-te-gadget .goog-te-combo {
    width: 100%;
  } */
  /* .network_btn {
    width: 100%;
    justify-content: space-between;
  } */
  /* .network_div {
    width: 100%;
  } */
  .right_menu .primary_btn {
    width: 100%;
  }
  .chart_box .hide_height {
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .swap_container_limit .limit_flx {
    display: block;
  }
  .swap_container_limit .limit_flx .right_box {
    width: 100%;
    margin-top: 10px;
    margin-left: unset !important;
  }
  /* .rotating_circle_keys::before {
    background-size: 56%;
    top: -30px;
  }
  .assets_section::before {
    background-size: 80%;
    right: 50px;
  }
  .wealth_section::before {
    background-size: 75%;
    left: -132px;
    top: 110px;
  } */
  .mobilelogo_center {
    position: relative;
    text-align: center;
  }
  .own_crypto_img {
    position: absolute;
    left: -30px;
    right: 0;
    height: 180px;
    margin: auto;
    top: -60px;
    bottom: 0;
  }
  .asset_sec_img {
    position: absolute;
    left: 0px;
    right: 0;
    width: 280px;
    margin: 0 auto;
    top: 24%;
  }
  .wealth_sec_img {
    position: absolute;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
    bottom: 0;
    top: 0;
  }
}
@media screen and (max-width: 1399px) {
  ul.main_navbar_list {
    margin-left: 0;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .rotating_circle_keys .rotating_circle::before,
  .assets_section .rotating_circle::before,
  .wealth_section .rotating_circle::before {
    display: none;
  }
  .banner_token_info {
    padding: 30px 50px;
  }
  .dropdown_menu_custom .dropdown-menu.show {
    display: block !important;
    position: absolute;
    width: 100%;
  }
  .dropdown_menu_custom .dropdown-menu {
    display: none !important;
  }
  /* .network_div {
    margin-bottom: 20px;
  } */
  /* .goog-te-gadget .goog-te-combo {
    margin: 20px 0 0 0 !important;
  } */
  ul.main_navbar_list li.nav-item {
    margin: 0;
  }
}
@media screen and (max-width: 1199px) {
  .swap_home {
    width: 100%;
  }
  .home_banner {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 991px) {
  .banbox{   
    text-align: center;
    margin: 20px auto;
  }
  footer [class*="col-"] {
    margin-bottom: 30px;
  }
  .network_row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .confirm_wallet_inp {
    font-size: 15px;
  }
  .page_header h1 {
    text-align: center;
  }
  .page_header h5 {
    text-align: center;
  }
  .banner_network_search_wrap {
    width: 100%;
  }
  .banner_token_info {
    flex-wrap: wrap;
  }
  .banner_token_info > div {
    width: 50%;
    margin: 15px 0;
  }
  .frame_box {
    margin-bottom: 30px;
    min-height: auto;
  }
  .sticky {
    position: relative;
    width: 100%;
    top: unset;
  }
  .landing_networks_div {
    justify-content: center;
  }
  .right_menu {
    display: block;
  }
  .network_div {
    margin-bottom: 20px;
  }
  .goog-te-gadget .goog-te-combo {
    margin: 20px 0 0 0 !important;
  }
  .goog-te-gadget .goog-te-combo {
    width: 100%;
  }
  .network_btn {
    justify-content: space-between;
  }
  .network_div {
    width: 100%;
  }
  .language-switch {
    margin-right: 0;
    margin-bottom: 20px;
    display: block;
  }
  .language-change-popup {
    position: relative;
    width: auto;
    top: unset;
    right: unset;
  }
  .popup-content {
    grid-template-columns: 1fr;
  }
  .own_crypto_img {    
    left: -20px;
    width: 150px;
    top: -20px;
  }
  .asset_sec_img {
    left: 0;
    width: 220px;
    top: 0;
    margin: auto;
    bottom: 0;
  }
  .wealth_sec_img {
    left: 0px;
    width: 140px;
    bottom: 0px;
  }
  .page_header {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  span.routing_row_swap_count {
    display: none;
  }
  .swap_info > div span,
  .bridge_info_accordian h2 button {
    font-size: 14px;
  }
  .bridge_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .bridge_right,
  .bridge_left {
    width: 100%;
  }
  .bridge_right {
    margin-bottom: 30px;
  }
  .blog_search_keywords {
    align-items: flex-start;
  }
  .blog_search_keywords ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .blog_content {
    min-height: auto;
  }
  .blog_search {
    max-width: 100%;
  }
  .main_title {
    font-size: 36px;
    line-height: 50px;
  }
  .sub_title {
    font-size: 30px;
  }
  .home_section {
    text-align: center;
    margin: 0 auto;
  }
  .home_section_div_flex {
    flex-wrap: wrap;
  }
  .home_section_div_left p.inner_para,
  .frame_box h2 {
    max-width: 100%;
  }
  .home_section_div {
    padding: 30px;
  }
  .home_section_div_right {
    margin-top: 15px;
  }
  .page_header h1 {
    font-size: 46px;
  }
  .frame_box {
    align-items: center;
  }
  .banner_token_info > div {
    width: 100%;
  }
  .gasfee_selector_wrap {
    flex-wrap: wrap;
  }
  .whole_popularlist {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .own_crypto_img {
    left: -30px;
    top: -30px;
    right: 0;
    bottom: 0;
    margin: auto;
    height: auto;
  }
  .asset_sec_img {
    left: 0px;
    top: 0%;
  }
  .wealth_sec_img {
    left: 0px;
    width: 160px;
    bottom: 0px;
  }
  .bridge_left .swap_box {
    background: #0f1117;
  }
  .blog_search input {
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .blog_detail_header img{
    height: auto;
  }
  .network_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .connect_wallet_row h3 {
    line-height: 24px;
  }
  .swap_box {
    padding: 20px 15px 50px;
  }
  .routing_row_single_inner {
    padding: 0 15px;
  }
  .routing_row_swap_card img:first-of-type {
    margin-right: -5px;
  }
  .routing_row_card > span {
    font-size: 12px;
    margin: 0 2px;
  }
  .custom_modal_chg {
    padding-left: 10px;
  }
  .active_order_filter {
    flex-wrap: wrap;
  }
  .whole_popularlist {
    grid-template-columns: 1fr 1fr;
  }
  .import_token_details {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
@media screen and (max-width: 440px) { 
  .swap_token_input,
  .slipage_inp_grp input,
  .slipage_inp_grp .input-group-text,
  .swap_token_title {
    font-size: 15px;
  }
  .otc_last .slipage_inp_grp input {
    padding: 8px 5px;
  }
  .otc_last_right .slipage_inp_grp .input-group-text {
    padding: 3px;
  }
}
@media screen and (max-width: 399px) {
  .modal_grp_btns {
    flex-wrap: wrap;
  }
  .modal_grp_btns button + button {
    margin-left: 0;
    margin-top: 12px;
  }
  .network_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .slipage_inp_grp {
    margin-top: 12px;
  }
  .bridge_confirmation_row > div::after {
    width: 60%;
  }
  .whole_popularlist {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 350px) {
  .heading_block_chg span a,
  .swap_token_title,
  .swap_token_input,
  .slipage_inp_grp input,
  .slipage_inp_grp .input-group-text,
  .otc_last_right .slipage_inp_grp .input-group-text select {
    font-size: 13px;
  }
  .otc_last {
    flex-direction: column;
    align-items: flex-start;
  }
  .otc_last_left,
  .otc_last_right {
    width: 100%;
  }
}
